<template>
    <div>
        <div v-if="item.motivation.show" v-html="item.motivation.content"></div>
        <div style="padding: 10px 0" v-if="item.horizontal">
            <v-slide-group
                    class="slide_listing"
                    v-model="active_listing_tab"
                    center-active
            >
                <v-slide-item
                        v-for="(listingItem, index) in listings"
                        :key="index"
                        v-slot="{ active, toggle }"
                >
                    <v-btn
                            :input-value="active_listing_tab === index"
                            outlined
                            small
                            depressed
                            class="mx-2"
                            style="border: none; border-radius: 12px"
                            :style="`background:${
              active_listing_tab === index
                ? item.styles.horizontal_active_background
                : item.styles.horizontal_background
            };color:${
              active_listing_tab === index
                ? item.styles.horizontal_active_color
                : item.styles.horizontal_color
            };`"
                            @click="
              toggle;
              scrollToTitle(index);
            "
                    >
                        {{ strip(listingItem.title) }}
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
        </div>
        <div v-if="item.styles.listing_vector === 'horizontal'" style="overflow:hidden;">
            <div
                    style="margin-bottom: 15px; display: flex; flex-direction: column"
                    v-for="(listingItem, index) in listings"
                    :key="index"
            >
                <div
                        v-if="content.showGroup"
                        :ref="`listing${index}`"
                        :style="`color:${content.styles.title.color};font-size:${content.styles.title.fontSize}px;font-weight:${content.styles.title.fontWeight};font-style:${content.styles.title.fontStyle};font-family:${content.styles.title.fontFamily};`"
                        style="height: auto; min-height: auto; padding: 5px"
                        v-html="htmlData(listingItem.title)"
                ></div>
                <swiper
                        ref="mySwiper"
                        :options="swiperOptions"
                >

                    <swiper-slide
                            :id="`listing_${index}_${listingIndex}`"
                            class="listing listing__item"
                            v-for="(listing, listingIndex) in listingItem.content"
                            :key="listingIndex"
                            :style="
              displayType(item.styles, listing) +
              `border-radius:${item.styles.borderListingRadius}px;overflow:hidden;padding:0;min-height:${listingHeight};width:${content.styles.width}px;`
            ">

                        <div
                                :class="`preview__inside__${index}__${listingIndex}`"
                                v-if="listing.preview"
                                style="z-index: -1; position: absolute; width: 100%; height: 100%"
                        ></div>

                        <div   @click="openDetail(index,listingIndex)"
                                :class="`listing__item__img__${index}__${listingIndex}`"
                                v-if="listing.main_img && item.styles.displayType !== 'inside'"
                                style="float: left;"
                                :style="`overflow:hidden;width:${
                item.styles.displayType === 'horizontal'
                  ? item.styles.listing_img_width + 'px'
                  : '100%'
              };min-height:${
                item.styles.listing_img_height
              }px;overflow:hidden;`"
                        >
                            <img   @click="openDetail(index,listingIndex)"
                                    :src="listing.main_img"
                                    alt=""
                                    :style="`height:${item.styles.listing_img_height}px;width:${
                  item.styles.displayType === 'horizontal'
                    ? item.styles.listing_img_width + 'px'
                    : '100%'
                };`"
                                    style="width: 100%"
                            />
                        </div>
                        <div   @click="openDetail(index,listingIndex)"
                                v-if="content.styles.content_position === 'center'"
                                :style="`min-height:${content.styles.lining_height}px;width:100%;font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
                        >
                            <div
                                    :style="`height:100%;width:100%;border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };padding:10px;`"
                            >
                                <p v-if="content.styles.show_location"
                                   class="listing__text"
                                   style="margin-bottom: 10px;"
                                   :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                >
                                    <v-icon :size="content.styles.descriptionFontSize">mdi-map-marker</v-icon> {{listing.map.location}}
                                </p>
                                <p
                                        class="listing__name"
                                        style="margin-bottom: 10px;"
                                        :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                >
                                    {{ listing.name }}
                                </p>
                                <p
                                        class="listing__text"
                                        style="margin-bottom: 10px;"
                                        v-if="listing.show_descr"
                                        :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                >
                                    <span v-if="content.styles.show_score_review"><v-icon color="orange" :size="content.styles.descriptionFontSize">mdi-star</v-icon> {{listing.reviews.score}}</span> <span v-if="content.styles.show_count_review">({{listing.reviews.count}} review)</span>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>


                </swiper>
            </div>

        </div>
        <div v-else>
                <div
                        class="draggable_group"
                        style="display: flex; flex-direction: column"
                        v-for="(listingItem, index) in listings"
                        :key="index"
                >
                    <div
                            v-if="content.showGroup"
                            :ref="`listing${index}`"
                            :style="`color:${content.styles.title.color};font-size:${content.styles.title.fontSize}px;font-weight:${content.styles.title.fontWeight};font-style:${content.styles.title.fontStyle};font-family:${content.styles.title.fontFamily};`"
                            style="height: auto; min-height: auto;margin-bottom:10px;"
                            v-html="htmlData(listingItem.title)"
                    ></div>
                        <div
                                v-for="(listing, listingIndex) in listingItem.content"
                                :key="listingIndex" @click="openDetail(index,listingIndex)" style="position: relative">

                            <div v-if="content.styles.show_favorite" style="position:absolute;top:15px;z-index:10;right:15px;cursor:pointer;" @click.stop="listing.favorite = !listing.favorite">
                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" :style="`fill:${listing.favorite?'#FF385C':'rgba(0, 0, 0, 0.5)'}`" style="display: block; height: 24px; width: 24px; stroke: #fff; stroke-width: 2; overflow: visible;"><path d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z"></path></svg>
                            </div>
                            <div
                                    v-if="content.styles.content_position === 'top' && item.styles.displayType === 'inside'"
                                    style="height: 100%"
                                    :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
                            >
                                <div
                                        :style="`border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };padding:10px;`"
                                >
                                    <p v-if="content.styles.show_location"
                                       class="listing__text"
                                       style="margin-bottom: 10px;"
                                       :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                    >
                                        <v-icon :size="content.styles.descriptionFontSize">mdi-map-marker</v-icon> {{listing.map.location}}
                                    </p>
                                    <p
                                            class="listing__name"
                                            style="margin-bottom: 10px;"
                                            :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                    >
                                        {{ listing.name }}
                                    </p>
                                    <p
                                            class="listing__text"
                                            style="margin-bottom: 10px;"
                                            v-if="listing.show_descr"
                                            :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                    >
                                        <span v-if="content.styles.show_score_review"><v-icon color="orange" :size="content.styles.descriptionFontSize">mdi-star</v-icon> {{listing.reviews.score}}</span> <span v-if="content.styles.show_count_review">({{listing.reviews.count}} review)</span>
                                    </p>
                                </div>
                            </div>
                            <div
                                    :id="`listing_${index}_${listingIndex}`"
                                    class="listing listing__item"
                                    style="
                position:relative;
                  display: block;
                  margin-bottom: 15px;
                  padding: 5px;
                "
                                    :style="
              displayType(item.styles, listing) +
              `overflow:hidden;border-radius:${item.styles.borderListingRadius}px;padding:0;min-height:${listingHeight};box-shadow:0 ${content.styles.shadow_size}px 6px ${content.styles.shadow_color};border:${content.styles.border_size}px solid ${content.styles.border_color};`
            "
                            >
                                <div
                                        :class="`preview__inside__${index}__${listingIndex}`"
                                        v-if="listing.preview"
                                        style="z-index: -1; position: absolute; width: 100%; height: 100%"
                                ></div>
                                <div
                                        :class="`listing__item__img__${index}__${listingIndex}`"
                                        v-if="listing.main_img && item.styles.displayType !== 'inside'"
                                        style="float: left; margin-right: 5px"
                                        :style="`overflow:hidden;width:${
                item.styles.displayType === 'horizontal'
                  ? item.styles.listing_img_width + 'px'
                  : '100%'
              };height:${
                item.styles.listing_img_height
              }px;`"
                                >
                                    <img
                                            :src="listing.main_img"
                                            alt=""
                                            :style="`margin-bottom:10px;height:${item.styles.listing_img_height}px;width:${
                  item.styles.displayType === 'horizontal'
                    ? item.styles.listing_img_width + 'px'
                    : '100%'
                };`"
                                            style="width: 100%"
                                    />
                                </div>
                                <div
                                        v-if="content.styles.content_position === 'center'&& item.styles.displayType === 'inside' || item.styles.displayType !== 'inside'"
                                        :style="`height:${item.styles.displayType === 'inside'?content.styles.lining_height+'px':'100%'};width:${item.styles.displayType === 'inside'?content.styles.lining_width+'%':'100%'};font-family:${content.styles.fontFamily};
                  padding-left: ${
                    item.styles.displayType !== 'horizontal' ? '0' : '10px'
                  };margin-left: ${
                    item.styles.displayType !== 'horizontal' ? '0' : '10px'
                  };`"
                                >
                                    <div
                                            :style="checkBorderRadius()+`;display:${item.styles.displayType === 'inside'?'flex':'block'};align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;padding:${
                  item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };padding:10px;`"
                                    >
                                        <p v-if="content.styles.show_location"
                                           class="listing__text"
                                           style="margin-bottom: 10px;"
                                           :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                        >
                                            <v-icon :size="content.styles.descriptionFontSize">mdi-map-marker</v-icon> {{listing.map.location}}
                                        </p>
                                        <p
                                                class="listing__name"
                                                style="margin-bottom: 10px;"
                                                :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                        >
                                            {{ listing.name }}
                                        </p>
                                        <p
                                                class="listing__text"
                                                style="margin-bottom: 10px;"
                                                v-if="listing.show_descr"
                                                :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                        >
                                            <span v-if="content.styles.show_score_review"><v-icon color="orange" :size="content.styles.descriptionFontSize">mdi-star</v-icon> {{listing.reviews.score}}</span> <span v-if="content.styles.show_count_review">({{listing.reviews.count}} review)</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                    v-if="content.styles.content_position === 'bottom'&& item.styles.displayType === 'inside'"
                                    style="height: 100%"
                                    :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
                            >
                                <div
                                        :style="`border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };padding:10px;`"
                                >
                                    <p v-if="content.styles.show_location"
                                       class="listing__text"
                                       style="margin-bottom: 10px;"
                                       :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                    >
                                        <v-icon :size="content.styles.descriptionFontSize">mdi-map-marker</v-icon> {{listing.map.location}}
                                    </p>
                                    <p
                                            class="listing__name"
                                            style="margin-bottom: 10px;"
                                            :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                                    >
                                        {{ listing.name }}
                                    </p>
                                    <p
                                            class="listing__text"
                                            style="margin-bottom: 10px;"
                                            v-if="listing.show_descr"
                                            :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                                    >
                                        <span v-if="content.styles.show_score_review"><v-icon color="orange" :size="content.styles.descriptionFontSize">mdi-star</v-icon> {{listing.reviews.score}}</span> <span v-if="content.styles.show_count_review">({{listing.reviews.count}} review)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
    </div>
</template>

<script>
import $axios from "@/plugins/axios"
import 'swiper/swiper-bundle.css'
import "swiper/modules/pagination/pagination.min.css"
import SwiperCore, {
  Pagination
} from 'swiper';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
SwiperCore.use([Pagination]);
import {mapState} from "vuex"
  export default {
    name: "TouristListingListPreview",
    props:['content','item'],
    components:{
      Swiper,
      SwiperSlide
    },
    data(){
      return{
        swiperOptions:{
          slidesPerView: 'auto',
          spaceBetween: 15,
        },
        active_listing_tab: 0,
        listings:[]
      }
    },
    watch:{
      "content.content":function (value) {
        if(value){
          this.checkData(value);
        }
      }
    },
    computed:{
      ...mapState(['landing']),
      dateToday: function () {
        return this.$moment().format("DD.MM.YYYY");
      },
      listingHeight: function () {
        if (this.item.styles.displayType === "inside") {
          return `${this.content.styles.height}px`;
        } else if (this.item.styles.displayType === "horizontal") {
          return `${+this.item.styles.listing_img_height + 10}px`;
        } else {
          return `${this.content.styles.height}px`;
        }
      },
    },
    methods:{
      openDetail(index,listingIndex){
        this.$store.state.touristListingDetail.group = index;
        this.$store.state.touristListingDetail.item = listingIndex;
      },
      strip(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
      },
      scrollToTitle(index) {
        this.active_listing_tab = index;
        let preview = document.getElementsByClassName("canvas");
        if (index === 0) {
          preview[0].scrollTop = 0;
        } else {
          preview[0].scrollTop = this.$refs[`listing${index}`][0].offsetTop - 50;
        }
      },
      async checkData(link){
        const {data} = await $axios.get(link);
        for(let i =0;i<data.length;i++){
          let item = data[i];
          let defaultListingGroup = {
            title: item.category,
            hide: false,
            content: [
              {
                map:{
                  lng:item.map_lng,
                  lat:item.map_lat,
                  location:item.location
                },
                reviews:{
                  count:item.review_count,
                  score:item.review_score
                },
                address:item.address,
                detail_link:item.link_to_detail,
                img: item.images?item.images:['https://tourist.hr/images/default/item.jpg'],
                video_link: item.link_to_video,
                name: item.title,
                descr: "",
                main_img: item.image?item.image:'https://tourist.hr/images/default/item.jpg',
                preview: false,
                full_descr: item.content,
                price: "100",
                hide: true,
                show_price: true,
                show_descr: true,
                name_color: "#000000",
                favorite:false,
                position:"center center",
                background_color:"rgba(255,255,255,1)",
                descr_color: "#000000",
                show_name_color: false,
                show_descr_color: false,
                id: item.id,
              },
            ],
          };
          const candidate = this.listings.find( x => x.title === defaultListingGroup.title);
          if(candidate){
            candidate.content.push(defaultListingGroup.content[0]);
          }else{
            this.listings.push(defaultListingGroup);
          }
        }
        this.$store.state.touristListings  = this.listings;
        this.$store.state.touristListingPage = this.item.page;
      },
      htmlData(data, type) {
        if (data) {
          const weatherInd = data.split(" ").indexOf(":weather:");
          let result = data.split(" ");
          if (weatherInd && this.landing.advanced.global.show_weather) {
            const newArr = result;
            newArr[weatherInd] =
              this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                0
              ) + " ℃";
            result = newArr;
          }
          const timeInd = data.split(" ").indexOf(":time:");
          if (timeInd) {
            const newArr = result;
            newArr[timeInd] = this.datenow;
            result = newArr;
          }
          const dateInd = data.split(" ").indexOf(":date:");
          if (dateInd) {
            const newArr = result;
            newArr[dateInd] = this.dateToday;
            result = newArr;
          }
          const user_name = data.split(" ").indexOf(":name:");
          if (
            user_name &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_name] = "Agent";
            result = newArr;
          }
          const user_surname = data.split(" ").indexOf(":surname:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_surname] = "Smith";
            result = newArr;
          }
          const user_birthday = data.split(" ").indexOf(":birthday:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_birthday] = "24.03.1999";
            result = newArr;
          }
          const user_room = data.split(" ").indexOf(":room:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_room] = "707";
            result = newArr;
          }

          const user_auth = data.split(" ").indexOf(":auth:");
          if (user_auth && this.landing.advanced.global.auth && !type) {
            const newArr = result;
            newArr[user_auth] = `<a href="#">Login</a>`;
            result = newArr;
          }
          const user_logout = data.split(" ").indexOf(":logout:");
          if (user_logout && this.landing.advanced.global.auth && !type) {
            const newArr = result;
            newArr[user_logout] = `<a href="#">Logout</a>`;
            result = newArr;
          }
          return result.join(" ");
        }
      },
      displayType(item, listing) {
        if (item.displayType === "horizontal") {
          return "flex-direction:row;align-items:normal;background-color:#fff;";
        } else if (item.displayType === "inside") {
          let bg = "";
          if (listing.preview) {
            bg = "transparent";
          } else {
            bg = `url(${listing.main_img}) no-repeat center / 100%`;
          }
          return `background:${bg};
                background-size:100%;
                overflow:hidden;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;
                display:flex;
                background-color:#fff;
                align-items:${this.content.styles.position.split(' ')[1]};
                justify-content:${this.content.styles.position.split(' ')[0]};`
        } else {
          return "flex-direction:column;background-color:#fff;";
        }
      },
      checkBorderRadius(){
        if(+this.content.styles.lining_width === 100){
          if(this.content.styles.position === 'flex-start flex-start' || this.content.styles.position === 'flex-end flex-start' || this.content.styles.position === 'center flex-start'){
            return `border-top-right-radius:${this.item.styles.borderListingRadius}px;border-top-left-radius:${this.item.styles.borderListingRadius}px;`
          }
          if(this.content.styles.position === 'flex-start flex-end' || this.content.styles.position === 'flex-end flex-end' || this.content.styles.position === 'center flex-end'){
            return `border-bottom-right-radius:${this.item.styles.borderListingRadius}px;border-bottom-left-radius:${this.item.styles.borderListingRadius}px;`
          }
        }else{
          return `border-radius:${
            this.item.styles.borderListingRadius
          }px;`
        }
      },
    },
    mounted() {
      if(this.item.styles.displayType === 'horizontal'){
        this.item.styles.displayType = 'default'
      }
      if(this.content.content && this.$store.state.touristListings.length === 0 && this.item.page === this.$store.state.touristListingPage){
        this.checkData(this.content.content);
      }
      else if(this.$store.state.touristListings.length && this.item.page === this.$store.state.touristListingPage){
        this.listings = JSON.parse(JSON.stringify(this.$store.state.touristListings));
      }
    }
  }
</script>

<style scoped>

</style>
